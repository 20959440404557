<!--门禁模块--通行记录(卡片记录)-->
<template>
  <div
    id="throughrecords"
    v-loading="loading"
    :element-loading-text="$t('throughrecords.a0')"
  >
    <!-- 头部 -->
    <div class="header">
      <my-headertitle>
        {{
          serviceType == 12 ? $t("throughrecords.a35") : $t("throughrecords.a1")
        }}
      </my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="4">
            <a-form-model-item :label="$t('throughrecords.a2')">
              <a-input
                :maxLength="30"
                allow-clear
                v-model="form.name"
                :placeholder="$t('throughrecords.a28')"
                v-InputText
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item :label="$t('throughrecords.a3')">
              <a-input
                :maxLength="30"
                allow-clear
                v-model="form.number"
                :placeholder="$t('throughrecords.a29')"
                v-Input-NumberText
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item :label="$t('throughrecords.a4')" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @change="handleCalendarChange"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item :label="$t('throughrecords.a5')" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">{{
                $t("throughrecords.a6")
              }}</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main" v-table-size="tableSize">
      <my-tabletitle>
        {{ $t("throughrecords.a7") }}
        <template v-slot:name>
          <a-button
            type="primary"
            icon="file-text"
            size="small"
            ghost
            :disabled="data.length == 0"
            @click="csvExport"
            style="margin-right: 20px"
          >
            {{ $t("alarm.a51") }}
          </a-button>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="data"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.params"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  type="primary"
                  style="margin-right: 15px"
                  @click="() => faceImage(record)"
                  v-if="record.authenticationMode == 88"
                  >{{ $t("throughrecords.a9") }}</a-button
                >
                <a-button type="primary" @click="() => annotation(record)">{{
                  $t("throughrecords.a10")
                }}</a-button>
              </template>
              <a>{{ $t("throughrecords.a11") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 注释 -->
    <a-modal
      :title="$t('throughrecords.a12')"
      centered
      :visible="visible"
      @cancel="annotationCancel"
      :getContainer="() => this.$refs.main"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="annotationCancel" :disabled="disabled">
          {{ $t("throughrecords.a13") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="annotationOk"
          :disabled="disabled"
        >
          {{ $t("throughrecords.a14") }}
        </a-button>
      </template>
      <div
        v-loading="annotationLoading"
        :element-loading-text="$t('throughrecords.a0')"
        class="annotation"
      >
        <a-textarea
          ref="text"
          v-model="value"
          allow-clear
          :placeholder="$t('throughrecords.a15')"
          :auto-size="{ minRows: 10, maxRows: 10 }"
        />
      </div>
    </a-modal>
    <!-- 人脸识别 -->
    <a-modal
      :title="$t('throughrecords.a9')"
      centered
      :width="640"
      :visible="faceVisible"
      @cancel="faceImageCancel"
      :getContainer="() => this.$refs.main"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="faceImageCancel">
          {{ $t("throughrecords.a13") }}
        </a-button>
      </template>
      <div
        v-loading="faceLoading"
        :element-loading-text="$t('throughrecords.a0')"
        class="faceImageVessel"
      >
        <div class="faceImage">
          <a-card hoverable style="width: 100%">
            <a-avatar
              shape="square"
              :size="480"
              icon="user"
              v-if="faceData == null"
            />
            <img
              slot="cover"
              alt="example"
              :size="480"
              :src="faceData.pix + faceData.snapPhoto"
              v-else-if="faceData !== null"
            />
            <a-card-meta>
              <template slot="description">
                <a-descriptions :title="$t('throughrecords.a34')">
                  <a-descriptions-item :label="$t('throughrecords.a33')">
                    {{ faceData == null ? "" : faceData.personNo }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a19')">
                    {{ faceData == null ? "" : faceData.personName }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a20')">
                    {{ faceData == null ? "" : faceData.cardNo }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a17')">
                    {{ faceData == null ? "" : faceData.dateTime }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a30')">
                    {{ faceData == null ? "" : faceData.equipmentName }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a24')">
                    {{ faceData == null ? "" : faceData.faceDeviceName }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a21')">
                    {{ faceData == null ? "" : faceData.tempurature }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('throughrecords.a31')">
                    {{ faceData == null ? "" : faceData.mask }}
                  </a-descriptions-item>
                </a-descriptions>
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { throughData, cardAnnotation, getFaceRecord } from "../../api/door";
import {
  tableSort,
  csvDownload,
  tableTitleDisplay,
  tableIndexDisplay,
} from "../../utils/utils";
import moment from "moment";

export default {
  name: "throughrecords",
  data() {
    return {
      endOpen: false,
      startDate: undefined,
      data: [],
      loading: false,
      size: { x: 1400, y: 240 },
      visible: false,
      value: "",
      params: "",
      disabled: false,
      annotationLoading: false,
      form: {
        name: "",
        number: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      rules: {
        date1: [
          {
            required: true,
            message: this.$t("throughrecords.a8"),
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            message: this.$t("throughrecords.a8"),
            trigger: "change",
          },
        ],
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("throughrecords.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          id: "2",
          title: this.$t("throughrecords.a17"),
          dataIndex: "dateTime",
          sorter: (a, b) => {
            return tableSort(a, b, "dateTime");
          },
          width: 180,
        },
        {
          id: "3",
          title: this.$t("throughrecords.a18"),
          dataIndex: "authenticationModeType", //eventText
          sorter: (a, b) => {
            return tableSort(a, b, "authenticationModeType");
          },
          width: 110,
        },
        {
          id: "4",
          title: this.$t("throughrecords.a19"),
          dataIndex: "personName",
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          width: 90,
        },
        {
          id: "13",
          title: this.$t("information.a256"),
          dataIndex: "nationalityName",
          sorter: (a, b) => {
            return tableSort(a, b, "nationalityName");
          },
        },
        {
          id: "5",
          title: this.$t("throughrecords.a20"),
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          width: 130,
        },
        {
          id: "6",
          title: this.$t("throughrecords.a21"),
          dataIndex: "bodyTemperature",
          sorter: (a, b) => {
            return tableSort(a, b, "bodyTemperature");
          },
          width: 70,
        },
        {
          id: "7",
          title: this.$t("throughrecords.a22"),
          dataIndex: "equipmentGrName",
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentGrName");
          },
          width: 110,
        },
        {
          id: "8",
          title: this.$t("throughrecords.a23"),
          dataIndex: "equipmentName1",
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName1");
          },
          width: 100,
        },
        {
          id: "9",
          title: this.$t("throughrecords.a24"),
          dataIndex: "equipmentName2",
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName2");
          },
          width: 180,
        },
        {
          id: "10",
          title: this.$t("throughrecords.a25"),
          dataIndex: "siteName",
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          width: 120,
        },
        {
          id: "11",
          title: this.$t("throughrecords.a26"),
          dataIndex: "description",
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
        },
        
        {
          id: "12",
          title: this.$t("throughrecords.a27"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      column: [],
      displayContent:
        JSON.parse(localStorage.getItem("throughrecords_tableTitle")) || [],
      faceVisible: false,
      faceLoading: false,
      defaultImage: "",
      faceData: null,
      serviceType: 3,
    };
  },
  watch: {
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "throughrecords_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // console.log("this.$route.query>>>>", this.$route.query);
    // console.log("siteType>>>>", this.$store.getters.siteType);
    let siteType = this.$store.getters.siteType;
    if ("IOW" === siteType) {
      this.serviceType = 11;
    } else if ("VISITOR" === siteType) {
      this.serviceType = 12;
    } else if ("ATD" === siteType) {
      this.serviceType = 13;
    }
    // console.log("serviceType>>>>", this.serviceType);
    // 表格表头
    const name = "throughrecords_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("throughrecords");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 118.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
            personName: this.form.name,
            cardFaceNo: this.form.number,
            serviceType: this.serviceType,
          };
          console.log(data)
          throughData(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    faceImage(record) {
      this.faceVisible = true;
      // console.log("faceImage record>>", record);
      let param = {
        siteId: this.$route.query.id,
        params: record.params,
      };
      this.faceLoading = true;
      // console.log("faceImage param>>", param);
      getFaceRecord(param)
        .then((res) => {
          this.faceLoading = false;
          if (res.data && res.data.length > 0) {
            this.faceData = res.data[0];
          } else {
            this.$message.error(this.$t("throughrecords.a32"));
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    faceImageCancel() {
      this.faceVisible = false;
      this.faceData = null;
    },
    // 注释按钮
    annotation(key) {
      this.visible = true;
      this.params = key.params;
      this.value = key.description;
      setTimeout(() => {
        this.$refs.text.focus();
      }, 5);
    },
    // 注释窗口确定按钮
    annotationOk() {
      this.annotationLoading = true;
      this.disabled = true;
      const data = {
        params: this.params,
        comment: this.value,
      };
      // console.log(data);
      cardAnnotation(data)
        .then((res) => {
          // console.log(res);
          this.annotationLoading = false;
          this.disabled = false;
          if (res.errorCode == "00") {
            this.visible = false;
            this.$message.success(res.msg);
            this.inquire();
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.annotationLoading = false;
          this.disabled = false;
        });
    },
    // 注释窗口取消按钮
    annotationCancel() {
      this.visible = false;
    },
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name =
        this.serviceType == 12
          ? this.$t("throughrecords.a35")
          : this.$t("throughrecords.a1");
      const fileName = `${date1}~${date2}${name}`;
      const data = this.data;
      const columns = this.columns;
      csvDownload(data, columns, fileName);
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      this.form.date2 = moment(val).endOf("day");
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const nowValue = moment();
      if (!startValue || !nowValue) {
        return false;
      }
      // 禁用大于计算机日期的日期值
      return startValue.valueOf() > nowValue.valueOf();
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      const _value = JSON.stringify(startValue);
      const value = JSON.parse(_value);
      const nowValue = moment().endOf("day");
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        endValue.valueOf() > nowValue.valueOf() ||
        moment(value).add(1, "months").valueOf() <= endValue.valueOf()
      );
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px;
  overflow: hidden;
}
#throughrecords {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 150px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  width: 100%;
  height: calc(100% - 150px);
  padding: 0px 20px;
  overflow: hidden;
}
div /deep/.ant-form label {
  white-space: nowrap;
}
.faceImageVessel {
  height: 550px;
  overflow: hidden;
}
.faceImage {
  height: 550px;
  padding: 20px;
  overflow: auto;
}
.annotation {
  padding: 20px;
}
</style>